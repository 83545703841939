<template>
  <div class="pd10">
    <el-card class="custom-card">
      <div slot="header" class="clearfix">
        <span class="border-l relative pdL10">{{$route.meta.title}}</span>
      </div>
      <!-- <el-form :model="searchForm" :inline="true"> -->
      <!-- <el-form-item label="时间" class="custom-form-item"> -->
      <el-date-picker v-model="searchForm.times" type="datetimerange" :picker-options="pickerOptions"
        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right" class="mgB10">
      </el-date-picker>
      <!-- </el-form-item> -->
      <!-- <el-form-item label="仓房" class="custom-form-item"> -->
      <el-select v-model="searchForm.houseId" placeholder="请选择仓房" class="mgL10 mgB10 custom-form-input">
        <el-option v-for="(item,index) in cangList" :key="index" :label="item.houseNo" :value="item.locaId">
        </el-option>
      </el-select>
      <!-- </el-form-item> -->
      <!-- <el-form-item> -->
      <el-button type="primary" @click="search" :loading="loading" :disabled="loading" plain class="mgL10 mgB10">搜索
      </el-button>
      <el-button type="danger" @click="del" class="mgL10 mgB10">删除</el-button>
      <el-dropdown split-button type="primary" @command="exportExcel" class="mgL10 mgB10">
        导出
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="0">详细导出</el-dropdown-item>
          <el-dropdown-item :command="1">汇总导出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown split-button type="primary" @command="printTable" class="mgL10 mgB10">
        打印
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="0">详细打印</el-dropdown-item>
          <el-dropdown-item :command="1">汇总打印</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- </el-form-item> -->
      <!-- </el-form> -->
      <el-table :data="tableData" border stripe v-loading="loading" @selection-change="handleSelectionChange"
        style="width: 100%;">
        <el-table-column type="selection" width="40" header-align="center" align="center">
        </el-table-column>
        <el-table-column prop="houseName" :show-overflow-tooltip="true" header-align="center" align="center"
          label="仓房名称"></el-table-column>
        <el-table-column prop="tempTime" :show-overflow-tooltip="true" header-align="center" align="center"
          label="检测时间"></el-table-column>
        <el-table-column prop="highestTemp" :show-overflow-tooltip="true" header-align="center" align="center"
          label="最高温"></el-table-column>
        <el-table-column prop="averageTemp" :show-overflow-tooltip="true" header-align="center" align="center"
          label="平均温"></el-table-column>
        <el-table-column prop="minimumTemp" :show-overflow-tooltip="true" header-align="center" align="center"
          label="最低温"></el-table-column>
        <el-table-column prop="storehouseTemp" :show-overflow-tooltip="true" header-align="center" align="center"
          label="仓温">
          <template slot-scope="scope">
            {{scope.row.storehouseTemp|tempFilter}}
          </template>
        </el-table-column>
        <el-table-column prop="storehouseDampness" :show-overflow-tooltip="true" header-align="center" align="center"
          label="仓湿">
          <template slot-scope="scope">
            {{scope.row.storehouseDampness|dampnessFilter}}
          </template>
        </el-table-column>
        <el-table-column prop="externalTemp" :show-overflow-tooltip="true" header-align="center" align="center"
          label="气温">
          <template slot-scope="scope">
            {{scope.row.externalTemp|tempFilter}}
          </template>
        </el-table-column>
        <el-table-column prop="externalDampness" :show-overflow-tooltip="true" header-align="center" align="center"
          label="气湿">
          <template slot-scope="scope">
            {{scope.row.externalDampness|dampnessFilter}}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="260" header-align="center" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button @click.native.prevent="solidRow(scope.row)" size="mini" class="custom-table-btn">
              立体
            </el-button>
            <el-button @click.native.prevent="planeRow(scope.row)" type="success" size="mini" class="custom-table-btn">
              平面
            </el-button>
            <el-button @click.native.prevent="levelRow(scope.row)" type="success" size="mini" class="custom-table-btn">
              单层
            </el-button>
            <el-button @click.native.prevent="delRow(scope.row)" type="danger" size="mini" class="custom-table-btn">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <table-page :page="searchForm.page" :limit="searchForm.limit" :total="total" @search-table="feach"></table-page>
    </el-card>
    <!-- 打印 导出选择框 -->
    <el-dialog class="custom-dialog" :title="printOrExcel?'打印条件':'导出条件'" :visible.sync="dialogVisible" width="380px"
      :append-to-body="true" @close="closeDialog">
      <Operation :hide-type="hideCang" :state="printOrExcel" @close="closeDialog" />
    </el-dialog>
  </div>
</template>

<script>
  import common from "@/common/index.js"
  var moment = require('moment');
  export default {
    components: {
      "table-page": () => import('@/components/TablePage'),
      "Operation": () => import('./components/Operation')
    },
    filters: {
      dampnessFilter(e) {
        return e > 100 ? '异常' : e;
      },
      tempFilter(e) {
        return e === 411.2 || e === -3276.7 || e === -2839.8 || e === 822.4 ? '未接' : e
      }
    },
    data() {
      return {
        searchForm: {
          times: '',
          houseId: '',
          page: 1,
          limit: 10
        },
        cangList: [],
        total: 0,
        tableData: [],
        loading: false,
        pickerOptions: {
          shortcuts: common.shortcuts
        },
        value2: '',
        ids: [],
        printOrExcel: true,
        dialogVisible: false,
        hideCang: false
      }
    },
    mounted() {
      this.getAbarnNames();
      this.search()
    },
    methods: {
      //关闭弹框
      closeDialog() {
        this.dialogVisible = false;
        this.printOrExcel = true;
      },
      //获取仓房信息
      getAbarnNames() {
        this.$axios.Get(this.$api.abarnNames, {}).then(res => {
          this.cangList = res.data;
        })
      },
      //获取测温数据
      getTestingTempPage(page, limit) {
        this.loading = true;
        let param = {
          houseId: this.searchForm.houseId,
          page: page,
          limit: limit
        }
        if (this.searchForm.times) {
          param['time'] = moment(this.searchForm.times[0]).format('YYYY-MM-DD HH:mm:ss')
          param['endTime'] = moment(this.searchForm.times[1]).format('YYYY-MM-DD HH:mm:ss')
        }
        this.$axios.Get(this.$api.testingTempPage, param).then(res => {
          this.searchForm.limit = limit
          this.searchForm.page = page
          this.tableData = res.data;
          this.total = res.count
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        })
      },
      //搜索
      search() {
        this.getTestingTempPage(1, this.searchForm.limit)
      },
      //删除
      del() {
        if (this.ids.length === 0) {
          this.$message({
            message: '至少选择一条数据',
            type: 'warning'
          });
          return;
        }
        this.testingTempDel(this.ids)
      },
      //单行删除
      delRow(row) {
        this.testingTempDel([row.id])
      },
      //删除
      testingTempDel(ids) {
        this.$confirm('此操作将永久删除选中测温数据吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let than = this;
          this.$axios.Post(this.$api.testingTempDel, {
            "ids[]": ids.toString()
          }).then(() => {
            than.$message({
              message: '删除成功',
              type: 'success'
            });
            than.getTestingTempPage(than.searchForm.page, than.searchForm.limit)
          })
        }).catch(() => {});
      },
      //导出
      exportExcel(e) {
        this.printOrExcel = false
        this.dialogVisible = true
        switch (e) {
          case 0:
            this.hideCang = false
            break;
          case 1:
            this.hideCang = true
            break;
        }
      },
      //打印
      printTable(e) {
        this.printOrExcel = true
        this.dialogVisible = true
        switch (e) {
          case 0:
            this.hideCang = false
            break;
          case 1:
            this.hideCang = true
            break;
        }
      },
      //分页
      feach(e) {
        this.getTestingTempPage(e.page, e.limit)
      },
      //表格复选框点击
      handleSelectionChange(e) {
        let ids = [];
        e.forEach(item => {
          ids.push(item.id)
        })
        this.ids = ids;
      },
      //立体
      solidRow(e) {
        this.$router.push({
          path: e.cangType === 0 ? '/solid/Ware' : '/solid/silo',
          query: {
            id: e.id,
            houseNoId: e.houseNoId,
            time: e.tempTime
          }
        })
      },
      //平面
      planeRow(e) {
        this.$router.push({
          path: '/temp/pane',
          query: {
            id: e.id,
            time: e.tempTime,
            houseNoId: e.houseNoId
          }
        })
      },
      //层级
      levelRow(e) {
        this.$router.push({
          path: '/temp/layer',
          query: {
            id: e.id
          }
        })
      }
    }
  }
</script>

<style>
</style>
