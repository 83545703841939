const oneStage = [
  "#0aa344",
  "#0eb83a",
  "#0EC83A",
  "#0FDC50",
  "#96ce54",
  "#9ed048",
  "#9ed900",
  "#a3d900",
  "#afdd22",
  "#bddd22",
  "#c9dd22"
];

const twoStage = [
  "#bce672",
  "#eaff56",
  "#faff72",
  "#fff143",
  "#eacd76",
  "#d9b611",
  "#e9bb1d",
  "#f0c239",
  "#f2be45",
  "#ffc64b"
];

const threeStage = [
  "#ffc773",
  "#ffb61e",
  "#ffa631",
  "#ffa400",
  "#fa8c35",
  "#ff8c31",
  "#ff8936",
  "#ff7500",
  "#ff4c00",
  "#ff2121"
];

export default {
  colors:["#336699", "#FFCC33", "#000000", "#FF9900", "#cc3333", "#339933", "#9933cc", "#663333", "#cccc99", "#ffff00",
    "#669933", "#990066", "#FF9966", "#003399", "#99cc00", "#ccff66", "#666699", "#006633", "#9966cc", "#996600",
    "#66cccc", "#cccc66", "#FF6600", "#FFD700", "#FFA500"
  ],
  cangTypes: [{
    name: '平房仓',
    value: 0
  }, {
    name: '立筒仓',
    value: 1
  }],
  shortcuts: [{
    text: '最近一周',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      picker.$emit('pick', [start, end]);
    }
  }, {
    text: '最近一个月',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      picker.$emit('pick', [start, end]);
    }
  }, {
    text: '最近三个月',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      picker.$emit('pick', [start, end]);
    }
  }]
}

//数组拆分 为新的小数组 array数组值  subGroupLength每个数组的长度
export function group(array, subGroupLength) {
  var index = 0;
  var newArray = [];
  while (index < array.length) {
    newArray.push(array.slice(index, index += subGroupLength * 1));
  }
  return newArray;
}

//判断输入的值是否为数字
export function isNotANumber(inputData) {
  //isNaN(inputData)不能判断空串或一个空格
  //如果是一个空串或是一个空格，而isNaN是做为数字0进行处理的，而parseInt与parseFloat是返回一个错误消息，这个isNaN检查不严密而导致的。
  if (parseFloat(inputData).toString() == "NaN") {
    return false;
  } else {
    return true;
  }
}


//表格显示
export function validTempTable(val, mTemp, hTemp) {
  var text = val;
  if (isNotANumber(val)) {
    if (text <= mTemp) {
      text = "#" + val;
    }
    if (text >= hTemp) {
      text = "*" + val;
    }
  }
  return text;
}

export function validateTempNo(val) {
  if (val === 411.2) {
    return "未接";
  } else if (val === -2839.8) {
    return "短路";
  } else if (val === -3276.7) {
    return "开路";
  } else if (val === 822.4) {
    return "无效";
  } else {
    return val;
  }
}

//验证温度值是否符合要求
export function validateTemp(val) {
  if (val === 411.2) {
    return "未接";
  } else if (val === -2839.8) {
    return "短路";
  } else if (val === -3276.7) {
    return "开路";
  } else if (val === 822.4) {
    return "无效";
  } else {
    return val + '℃';
  }
}

//获取颜色  val值
export function getColorTemp(val) {
  var colos = {
    "bgColor": "#7FFF00",
    "fontColor": "#fcfcfc"
  };
  if (val === 411.2 || val === -3276.7 || val === -2839.8 || val === 822.4 || val === '未接' || val === '无效') {
    colos.bgColor = "#fcfcfc";
  } else if (val < 0) {
    colos.bgColor = '#057748';
  } else if (val > 30) {
    colos.bgColor = '#dc3023';
  } else {
    if (val >= 0 && val < 11) {
      colos.bgColor = oneStage[Math.floor(val)];
    } else if (val >= 11 && val < 21) {
      colos.bgColor = twoStage[Math.floor(val) - 11];
    } else if (val >= 21 && val <= 30) {
      colos.bgColor = threeStage[Math.floor(val) - 21];
    }
  }
  return colos;
}



//获取平面表格最高温和最低温
export function tablePlaneColor(val, mTemp, hTemp) {
  var color = '';
  if (isNotANumber(val)) {
    if (val <= mTemp) {
      color = 'color:#1cbbad;font-weight: bold;';
    }
    if (val >= hTemp) {
      color = 'color:#EE0000;font-weight: bold;';
    }
  }
  return color;
}

export function siloCol(col) {

  let cols = [];
  for (var i = 0; i < col; i++) {
    cols.push({
      value: i,
      field: 'field' + i,
      name: "第" + (i * 1 + 1) + "根"
    })
  }
  return cols
}
export function siloData(val) {
  let rows = [],
    arrays = val.temps;
  for (let i = 0; i < val.high; i++) {
    let array = arrays[i];
    let row = {
      high: "第" + (i * 1 + 1) + "层"
    }
    for (let j in array) {
      let title = (i * 1 + 1) + "层 " + (j * 1 + 1) + "根 点位 " + (i + (val.high * 1 * j));
      // row['field' + j] = this.validTempTable(this.validateTempNo(array[j]), val.minimumTemp, val.highestTemp)
      // html += "<td style='" + tablePlaneColor(array[j], val.minTemp, val.maxTemp) + "'>" + (array[j] == 'k' ? "" :
      //   validTempTable(array[j], val.minimumTemp, val.highestTemp)) + "</td>"
      row['field' + j] = {
        value: array[j] == 'k' ? "" : validTempTable(validateTempNo(array[j]), val.minimumTemp, val
          .highestTemp),
        title: title,
        cellData: i + (val.high * 1 * j),
        oldValue: array[j]
      }
    }

    rows.push(row)
  }
  return rows

}

export function wareCol(col, postion) {
  let cols = [];
  if (postion == 2 || postion == 3) {
    for (let i = col - 1; i >= 0; i--) {
      cols.push({
        value: i,
        field: 'field' + i,
        name: "第" + (i * 1 + 1) + "列"
      })
    }
  } else {
    for (let i = 0; i < col; i++) {
      cols.push({
        value: i,
        field: 'field' + i,
        name: "第" + (i * 1 + 1) + "列"
      })
    }
  }
  return cols
}


export function wareData(val) {
  var arrays = group(val.temps, val.high);
  let rows = []
  if (val.startPointPosition == 1 || val.startPointPosition == 3) {
    var count = 0;
    for (let i = val.row - 1; i >= 0; i--) {
      let array = arrays[count];
      for (let j in array) {
        let row = {
          row: "第" + (i * 1 + 1) + "行",
          high: "第" + (j * 1 + 1) + "层"
        }
        let data = array[j];
        for (let k in data) {
          let v = val.row * val.high * (data.length - k - 1) + i * val.high + j * 1;
          let title = (i * 1 + 1) + "行 " + (j * 1 + 1) + "层 " + (val.col - k * 1) + "列 点位 " + v;
          row['field' + k] = {
            value: validTempTable(validateTempNo(data[k]), val.minimumTemp, val.highestTemp),
            title: title,
            cellData: v,
            oldValue: data[k]
          }
          // html += "<td style='" + tablePlaneColor(data[k], val.minTemp, val.maxTemp) + "'>" + +"</td>"
        }
        rows.push(row)
      }
      count++;
    }
  } else {
    for (let i = 0; i < val.row; i++) {
      let array = arrays[i];
      for (let j in array) {
        let row = {
          row: "第" + (i * 1 + 1) + "行",
          high: "第" + (j * 1 + 1) + "层"
        }
        let data = array[j];
        for (let k in data) {
          var v = val.row * val.high * k + i * val.high + j * 1;
          var title = (i * 1 + 1) + "行 " + (j * 1 + 1) + "层 " + (k * 1 + 1) + "列 点位 " + v;
          row['field' + k] = {
            value: validTempTable(validateTempNo(data[k]), val.minimumTemp, val.highestTemp),
            title: title,
            cellData: v,
            oldValue: data[k]
          }
        }
        rows.push(row)
      }
    }
  }
  return rows
}


//温度汇总
export function wareSumCol(tempInfo) {
  let cols = [{
    field: "title",
    name: ""
  }, {
    field: "desc",
    name: "",

  }, {
    field: "all",
    name: "所有层",
  }];

  for (var i = 0; i < tempInfo.high; i++) {
    cols.push({
      field: 'field' + i,
      name: "第" + (i * 1 + 1) + "层"
    })
  }
  return cols
}


export function wareSumData(tempInfo) {
  let rows = [],
    highTempSummarys = tempInfo.highTempSummarys;
  let maxTemp = {
    "title": "温度汇总",
    "desc": "最高温度",
    "all": tempInfo.maxTemp
  }
  for (let i = 0; i < tempInfo.high; i++) {
    maxTemp['field' + i] = highTempSummarys[i].maxTemp
  }
  let avgTemp = {
    "title": "温度汇总",
    "desc": "平均温度",
    "all": tempInfo.avgTemp
  }
  for (var k = 0; k < tempInfo.high; k++) {
    avgTemp['field' + k] = highTempSummarys[k].avgTemp
  }
  let minTemp = {
    "title": "温度汇总",
    "desc": "最低温度",
    "all": tempInfo.minTemp
  }
  for (var j = 0; j < tempInfo.high; j++) {
    minTemp['field' + j] = highTempSummarys[j].minTemp
  }
  rows.push(maxTemp)
  rows.push(avgTemp)
  rows.push(minTemp)
  return rows
}


export function archivesData(tempInfo) {
  return [{
    'field0': '仓房档案',
    'field1': tempInfo.cangType,
    'field2': tempInfo.cangLong,
    'field3': tempInfo.cangWide,
    'field4': tempInfo.liangHeight,
    'field5': tempInfo.designReserves,
    'field6': tempInfo.actualReserves
  }, {
    'field0': '仓房档案',
    'field1': tempInfo.storekeeper,
    'field2': tempInfo.liangVarieties,
    'field3': tempInfo.reservesProperty,
    'field4': tempInfo.enterTime,
    'field5': tempInfo.enterDew,
    'field6': tempInfo.producingArea
  }, {
    'field0': '仓房档案',
    'field1': tempInfo.impurity,
    'field2': tempInfo.roughness,
    'field3': tempInfo.imperfectGrain,
    'field4': tempInfo.waterAbsorption,
    'field5': tempInfo.germinationPercentage,
    'field6': tempInfo.grainSituationAnalysis
  }]
}


//获取最高温最低温
export function getMinAndHighTemp(val) {
  var temps = {
    "minTemp": null,
    "maxTemp": null
  };
  if (val.length > 0) {
    for (let i in val) {
      if (isNotANumber(val[i])) {
        temps.minTemp = val[i];
        temps.maxTemp = val[i];
        break;
      }
    }
  }

  for (let i in val) {
    if (isNotANumber(val[i])) {
      if (val[i] <= temps.minTemp) {
        temps.minTemp = val[i];
      } else if (val[i] >= temps.maxTemp) {
        temps.maxTemp = val[i];
      }
    }
  }
  return temps;
}